export default {
  liveList: '/course/liveRoom/findLiveRoom/byPage',
  updateStatus: '/course/liveRoom/updateLiveRoom/publishStatus',
  createdLive: '/course/liveRoom/createLive',
  editSearch: '/course/liveRoom/findLiveRoom/byLiveId',
  editLive: '/course/liveRoom/updateLiveRoom/updateInformation',
  goLive: '/course/liveRoom/findLiveRoom/goLive',
  teacherSelect: '/course/liveRoom/query/liveteacher/selectlist',
  liveRecord: '/course/userLive/user/study/record/page',
  livePlayback: '/course/liveRoom/query/liveroom/playback',
  livePlayInfo: '/course/video/play/info',
  mealPayRecord: '/course/livePackage/org/list',
  liveMeal: '/course/livePackage/list',
  allMealBuyRecord: '/course/livePackage/records',
  buyMeal: '/order/identify/orgOrder/enterprise/purchaseLiveService',
  deleteLive: '/course/liveRoom/delete/byLiveIds',
  closeLive: '/course/liveRoom/close/byLiveId',
  updateLiveVideo: '/course/liveRoom/updateLiveBack',

  liveVedioList: '/course/live/play/back/clip/list',
  sortVedio: '/course/live/play/back/clip/sort',
  // 腾讯文件上传--签名
  tencentUploadSignature: '/third/api/tencentUpload/upload/file/signature',
  addBackVedio: '/course/live/play/back/clip/add',
  renameBackVedio: '/course/live/play/back/clip/rename',
  backVedioDelete: '/course/live/play/back/clip/delete',
  backVedioEnable: '/course/live/play/back/clip/enable',
}
