<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title" @click="goBack"><i class="el-icon-arrow-left"></i>返回</span>
      </div>
      <div class="content">
        <div class="contentHeader">
          <div class="title">{{ this.$route.query.liveName }}</div>
          <div class="teacher">讲师：{{ this.$route.query.teacherName }}</div>
        </div>
        <div id="prism-player" class="prism-player"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { livePlayInfo } from '@/api/live'
import to from 'await-to'
export default {
  data() {
    return {
      liveId: this.$route.query.liveId,
      videoId: this.$route.query.videoId,
      videoPlatform: this.$route.query.videoPlatform,
    }
  },
  created() {
    this.livePlaybackData()
  },
  methods: {
    async livePlaybackData() {
      const [res, err] = await to(
        livePlayInfo({ videoId: this.videoId, videoPlatform: this.videoPlatform }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      if (!res.data.videoUrl) return
      this.$nextTick(() => {
        if (this.player) {
          this.player.loadByUrl(res.data.videoUrl)
          this.player.setCover(this.$route.query.livePic)
          return
        }
        // eslint-disable-next-line no-undef
        this.player = new Aliplayer({
          id: 'prism-player',
          cover: this.$route.query.livePic,
          source: res.data.videoUrl,
          width: '100%',
          height: '500px',
          autoplay: true,
          isLive: false,
          rePlay: true,
          controlBarVisibility: 'hover',
        })
      })
    },
    goBack() {
      this.$router.push('/live/liveList')
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  cursor: pointer;
}
.contentHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70px;
  background: #232323;
  color: #fff;
  padding-left: 27px;
  box-sizing: border-box;
  .title {
    font-size: 20px;
    color: #fff;
  }
  .teacher {
    font-size: 14px;
    color: #909399;
  }
}
</style>
