import config from './config/live'
import axios from '@/utils/request'
export const liveList = params => axios.post(config.liveList, params) // 直播列表
export const updateStatus = params => axios.post(config.updateStatus, params) // 修改直播状态
export const createdLive = params => axios.post(config.createdLive, params) // 创建直播
export const editSearch = params => axios.post(config.editSearch, params) // 编辑查询直播
export const editLive = params => axios.post(config.editLive, params) // 编辑直播
export const goLive = params => axios.post(config.goLive, params) // 去直播
export const teacherSelect = params => axios.post(config.teacherSelect, params) // 讲师下拉
export const liveRecord = params => axios.post(config.liveRecord, params) // 直播记录
export const livePlayback = params => axios.post(config.livePlayback, params) // 直播回放
export const livePlayInfo = params => axios.post(config.livePlayInfo, params) // 直播回放
export const mealPayRecord = params => axios.post(config.mealPayRecord, params) // 套餐购买有效期记录
export const liveMeal = params => axios.post(config.liveMeal, params) // 套餐列表
export const allMealBuyRecord = params => axios.post(config.allMealBuyRecord, params) // 全部套餐购买记录
export const buyMeal = params => axios.post(config.buyMeal, params) // 确定购买
export const deleteLive = params => axios.post(config.deleteLive, params) // 删除直播
export const closeLive = params => axios.post(config.closeLive, params) // 结束直播
export const updateLiveVideo = params => axios.post(config.updateLiveVideo, params) // 修改回放

export const liveVedioList = params => axios.post(config.liveVedioList, params) // 回放列表
export const sortVedio = params => axios.post(config.sortVedio, params) // 回放排序
export const tencentUploadSignature = params => axios.post(config.tencentUploadSignature, params)
export const addBackVedio = params => axios.post(config.addBackVedio, params) // 添加回放
export const renameBackVedio = params => axios.post(config.renameBackVedio, params) // 回放重命名
export const backVedioDelete = params => axios.post(config.backVedioDelete, params) // 回放删除
export const backVedioEnable = params => axios.post(config.backVedioEnable, params) // 回放启用禁用
